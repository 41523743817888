import './scss/style.scss';
import 'bootstrap';
import { WOW } from 'wowjs';
import { tns } from '../node_modules/tiny-slider/src/tiny-slider';
import { appear } from '../node_modules/jquery.appear/jquery.appear';

new WOW({
    live: false,
    scrollContainer: null
}).init();

if ($.contains(document.body, document.getElementById('slider-main'))) {

    var slider_main = tns({
        container: '#slider-main',
        items: 1,
        loop: true,
        slideBy: 'page',
        mode: 'gallery',
        autoplay: true,
        speed: 1000,
        autoplayTimeout: 10000,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        mouseDrag: true,
    });

    document.querySelector('.btn-slider.btn-left').onclick = function(event) {
        event.preventDefault();

        slider_main.goTo('prev');
    };

    document.querySelector('.btn-slider.btn-right').onclick = function(event) {
        event.preventDefault();

        slider_main.goTo('next');
    };
}

if ($.contains(document.body, document.getElementById('slider-carousel-client'))) {

    var slider_carousel_client = tns({
        container: '#slider-carousel-client',
        items: 5,
        loop: true,
        slideBy: 'page',
        mode: 'carousel',
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayButtonOutput: false,
        speed: 1000,
        controls: false,
        nav: true,
        navPosition: 'bottom',
        mouseDrag: true,
        responsive: {
            0: {
                items: 1
            },
            380: {
                items: 1
            },
            640: {
                items: 2
            },
            700: {
                items: 3
            },
            900: {
                items: 4
            },
            1000: {
                items: 5
            }
        }
    });
}

if ($.contains(document.body, document.getElementById('slider-projects-main'))) {

    var slider_projects_main = tns({
        container: '#slider-projects-main',
        items: 2,
        loop: true,
        slideBy: 'page',
        mode: 'carousel',
        autoplay: false,
        speed: 1000,
        controls: false,
        nav: false,
        mouseDrag: true,
        responsive: {
            0: {
                items: 1
            },
            380: {
                items: 1
            },
            640: {
                items: 2
            }
        }
    });

    document.querySelector('.block-projects-main .btn-slider.btn-left').onclick = function(event) {
        event.preventDefault();

        slider_projects_main.goTo('prev');
    };

    document.querySelector('.block-projects-main .btn-slider.btn-right').onclick = function(event) {
        event.preventDefault();

        slider_projects_main.goTo('next');
    };
}

if ($.contains(document.body, document.getElementById('slider-about-us'))) {

    var slider_about_us = tns({
        container: '#slider-about-us',
        items: 1,
        loop: true,
        slideBy: 'page',
        mode: 'carousel',
        autoplay: false,
        speed: 1000,
        controls: false,
        nav: false,
        mouseDrag: true,
    });

    document.querySelector('.btn-container-slider-about-us .btn-slider.btn-left').onclick = function(event) {
        event.preventDefault();

        slider_about_us.goTo('prev');
    };

    document.querySelector('.btn-container-slider-about-us .btn-slider.btn-right').onclick = function(event) {
        event.preventDefault();

        slider_about_us.goTo('next');
    };
}